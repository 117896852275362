import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from 'hooks/useWindowSize'
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  xButton: {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // margin: theme.spacing(1),
    gridColumn: '1 / span 1',
    gridRow: '1/ span 1',
    color: theme.palette.primary.main,
    transition: 'all 150ms ease-in-out',
    alignItems: 'center',
    justifyItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1) rotate(360deg)'
    },
    '&:active': {
      transform: 'scale(1)'
    }
  },
  xButtonBackground: {
    gridColumn: '1 / span 1',
    gridRow: '1/ span 1',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    height: 45,
    width: 45,
    justifySelf: 'center',
    alignSelf: 'center'
    // position: 'fixed'
  },
  container: {
    height: 48,
    width: 48,
    position: 'fixed',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    zIndex: theme.zIndex.modal - 2
  }
}))

const XButton = ({ onClick, ...props }) => {
  const classes = useStyles()
  const wS = useWindowSize()
  return (
    <motion.div
      layout
      className={classes.container}
      style={{
        top: isMobile ? wS.height * 0.1 - 24 : wS.height * 0.4 - 24
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <div className={classes.xButtonBackground} />
      <FontAwesomeIcon
        onClick={onClick}
        className={classes.xButton}
        icon={faTimesCircle}
        size='3x'
        {...props}
      />
    </motion.div>
  )
}

export default XButton
