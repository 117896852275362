import { useEffect } from 'react'

const usePreventBodyScroll = (isPrevented = false, active = true) => {
  useEffect(() => {
    if (active) {
      if (isPrevented) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }
    }

    return () => {
      if (active) {
        document.body.style.overflow = 'unset'
      }
    }
  }, [isPrevented, active])
}

export default usePreventBodyScroll
