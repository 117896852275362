import React, { useState, lazy, Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Home from 'views/Home/Home'
import UploadButton from 'components/Buttons/UploadButton/UploadButton'
import ExitButton from './components/Buttons/ExitButton/ExitButton'
import Logo from 'components/Logo/Logo'
import SEO from 'react-seo-component'
import UploadForm from './components/Forms/UploadForm/UploadForm'
import NavFallback from 'components/Nav/NavFallback'
import FullPageFallback from './components/FullPage/FullPageFallback'
import BackgroundFallback from './components/Background/BackgroundFallback'
import SlideInBackdrop from './components/Modals/SlideInModal/SlideInBackdrop'
const FAQs = lazy(() => import('views/FAQ/FAQs'))
const Release = lazy(() => import('views/Release/Release'))
const AboutUs = lazy(() => import('views/AboutUs/AboutUs'))
const Awareness = lazy(() => import('views/Awareness/Awareness'))
const Documenting = lazy(() => import('views/Documenting/Documenting'))
const Connecting = lazy(() => import('views/Connect/Connecting'))
const Background = lazy(() => import('components/Background/Background'))
const Services = lazy(() => import('views/Services/Services'))
const Nav = lazy(() => import('components/Nav/Nav'))

const {
  REACT_APP_SITE_DESC,
  REACT_APP_SITE_NAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_SITE_TWITTER
} = process.env

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden'
    }
  },
  backgroundBackdrop: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('sm')]: {
      background: 'rgba(255, 255, 255, 0.8)'
    }
  }
}))

const PAGES = [
  {
    to: 'services',
    linkText: 'Services',
    Component: ({ ...props }) => <Services {...props} />
  },
  {
    to: 'release',
    linkText: 'Release of Information',
    Component: ({ ...props }) => <Release {...props} />
  },
  {
    to: 'awareness',
    linkText: 'Awareness',
    Component: ({ ...props }) => <Awareness {...props} />
  },
  {
    to: 'documenting',
    linkText: 'Documenting Evidence',
    Component: ({ ...props }) => <Documenting {...props} />
  },
  {
    to: 'about-us',
    linkText: 'About Us',
    Component: ({ ...props }) => <AboutUs {...props} />
  },
  {
    to: 'faqs',
    linkText: 'FAQs',
    Component: ({ ...props }) => <FAQs {...props} />
  },
  {
    to: 'connect',
    linkText: 'Contact',
    Component: ({ ...props }) => <Connecting {...props} />
  }
]

const App = () => {
  const classes = useStyles()

  const [uploadFormVisibility, setUploadFormVisibility] = useState(false)

  const toggleFormVisibility = () => {
    setUploadFormVisibility(prev => {
      if (!prev) {
        if(window.location.hostname === 'dvsafe.org'){
        window.gtag('event', 'Upload Open', {
          event_category: 'conversions'
        })
      }
      }
      return !prev
    })
  }
  return (
    <Grid
      className={classes.root}
      container
      direction='column'
      alignItems='center'
      justify='flex-start'
    >
      <SEO
        title={REACT_APP_SITE_TITLE}
        titleTemplate={REACT_APP_SITE_NAME}
        titleSeparator={`|`}
        description={REACT_APP_SITE_DESC}
        pathname='https://dvsafe.org/'
        siteLanguage='EN'
        siteLocale='US'
        twitterUsername={REACT_APP_SITE_TWITTER}
      />
      <UploadButton onClick={toggleFormVisibility} />
      <UploadForm
        visible={uploadFormVisibility}
        toggleVisibility={toggleFormVisibility}
      />
      <SlideInBackdrop visible={uploadFormVisibility} />
      <ExitButton url={'https://google.com'} />
      <Logo />
      <Home />
      <Suspense fallback={<NavFallback />}>
        <Nav pages={PAGES} />
      </Suspense>
      <Suspense fallback={<BackgroundFallback />}>
        <Background />
      </Suspense>
      {PAGES.map(({ Component, to }, idx) => (
        <Suspense key={`${to}-app-key`} fallback={<FullPageFallback />}>
          <Component darkerBackground={idx % 2 === 0} />
        </Suspense>
      ))}
    </Grid>
  )
}

export default App
