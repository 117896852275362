import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
// import ExitSign from './ExitSign/ExitSign'
import { faRunning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobile } from 'react-device-detect'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    top: 16,
    right: 16,
    height: 40,
    zIndex: theme.zIndex.modal - 2,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    '& *': {
      transition: 'all 120ms ease-in-out',
      color: theme.palette.common.white
    },
    '&:hover': {
      // border: `1px solid ${theme.palette.primary.main}`,
      transition: 'all 120ms ease-in-out',
      background: theme.palette.common.white,
      '& *': {
        color: theme.palette.primary.main
      }
    }
  },
  link: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:any-link': {
      textDecoration: 'none'
    }
  },
  leaveText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9rem',
    fontWeight: 600,
    textDecoration: 'none',
    userSelect: 'none',
    '&:hover': {
      // color: theme.palette.primary.main
    },
    '&:visted': {
      textDecoration: 'none'
    },
    '&:focus': {
      textDecoration: 'none'
    }
  }
}))

const ExitButton = ({ url }) => {
  const classes = useStyles()
  return (
    <Slide
      in
      direction='left'
      timeout={{ appear: 1000, enter: 500, exit: 300 }}
    >
      <div
        className={classes.container}
        style={{ height: isMobile ? 33 : 40, width: 115 }}
      >
        <a className={classes.link} href={url}>
          <span className={classes.leaveText}>
            EXIT NOW
            <FontAwesomeIcon
              icon={faRunning}
              size='lg'
              style={{ marginLeft: 5 }}
            />
          </span>
        </a>
      </div>
    </Slide>
  )
}

ExitButton.propTypes = {
  /**
   * Url to redirect to when clicked
   */
  url: PropTypes.string.isRequired
}

export default ExitButton
