import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Element } from 'react-scroll'
import useCls from 'hooks/useCls'

const useStyles = makeStyles(theme => ({
  fullPage: {
    minHeight: '100vh',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    margin: 0,
    padding: theme.spacing(1, 1),
    paddingTop: 80
    // padding: theme.spacing(2, 8),
    // [theme.breakpoints.between(0, 850)]: {
    //   paddingTop: '81px'
    //   // minHeight: 'calc(100vh - 81px)'
    // }
  }
}))

const FullPage = ({
  name,
  className,
  children,
  justify = 'flex-start',
  darkerBackground = false,
  ...props
}) => {
  const classes = useCls([useStyles().fullPage, className])
  return (
    <Element
      className={classes}

      name={name}
      {...props}
      style={{
        justifyContent: justify,
        backgroundColor: darkerBackground ? 'rgba(0,0,0,0.6)' : 'transparent'
      }}
    >
      {children}
    </Element>
  )
}

FullPage.propTypes = {
  /**
   * Appends additional classNames to fullPage component.
   */
  className: PropTypes.string,
  /**
   * A unique name for the FullPage component.
   * Used as the link name for react-scroll Link elements.
   */
  name: PropTypes.string.isRequired
}

export default FullPage
