import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let modalTheme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#ed2b31'
    },
    secondary: {
      main: '#767676'
    },
    type: 'light',
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h3: {
      fontWeight: 700
    }
  },
  overrides: {
    MuiAppBar: {
      positionFixed: {
        top: 'auto',
        bottom: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        color: '#222',
        '&$focused': {
          color: '#222',
          borderColor: '#222'
        },
        '&:hover': {
          borderColor: '#ed2b31'
        }
      },
      notchedOutline: {
        borderColor: '#222'
      },
      input: {
        color: '#222'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 10
      }
    }
  }
})

modalTheme = responsiveFontSizes(modalTheme)

export default modalTheme
