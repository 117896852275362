import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { motion, AnimatePresence } from 'framer-motion'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.modal - 4,
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: '100%',
    height: '100vh'
  }
}))

const SlideInBackdrop = ({ visible }) => {
  const classes = useStyles()
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={classes.backdrop}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      )}
    </AnimatePresence>
  )
}

export default SlideInBackdrop
