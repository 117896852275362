import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let mainTheme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#ed2b31'
    },
    secondary: {
      main: '#d9d9d9'
    },
    type: 'dark',
    background: {
      modal: '#fff'
    },
    text: {
      modal: '#767676'
    }
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h3: {
      fontWeight: 700,
      fontFamily: '"Lato", sans-serif'
    },
    h2: {
      fontWeight: 700,
      fontFamily: '"Lato", sans-serif'
    },
    h1: {
      fontSize: '1px',
      color: 'transparent',
      fontFamily: '"Lato", sans-serif'
    },
    h4: {
      fontWeight: 700
    },
    subtitle1: {
      fontWeight: 'bold',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1.25rem'
    }
  },
  overrides: {
    MuiAppBar: {
      positionFixed: {
        top: 'auto',
        bottom: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        color: '#222',
        '&$focused': {
          color: '#222',
          borderColor: '#222'
        },
        '&:hover': {
          borderColor: '#ed2b31'
        }
      },
      notchedOutline: {
        borderColor: '#222'
      },
      input: {
        color: '#222'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 10
      }
    }
  }
})

mainTheme = responsiveFontSizes(mainTheme)

export default mainTheme
