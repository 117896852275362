import React from 'react'
import FullPage from 'components/FullPage/FullPage'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {},
  about: {
    padding: theme.spacing(2),
    maxWidth: '48rem',
    marginTop: -40
  },
  headline: {
    padding: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      color: '#000',
      display: 'block',
      fontSize: '.9em',
      marginTop: '-36px',
      marginLeft: '-7px'
    }
  },
  button: {
    background: '#ed2b31',
    borderRadius: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
}))

const Home = () => {
  const classes = useStyles()
  return (
    <FullPage name='home' className={classes.root} justify='center'>
      <Typography
        className={classes.about}
        variant='h4'
        color='textPrimary'
        align='center'
      >
        An organization dedicated to providing victims of domestic violence with
        a free way to safely store evidence of their abuse.
      </Typography>
    </FullPage>
  )
}

export default Home
