import React from 'react'

const BackgroundFallback = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: -1,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)'
    }}
  />
)

export default BackgroundFallback
