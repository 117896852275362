import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import logoImg from 'assets/img/logo_white.png'
import useWindowSize from 'hooks/useWindowSize'
import { Link } from 'react-scroll'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  linkContainer: {
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  img: {
    maxWidth: '100%',
    userSelect: 'none'
  }
}))

const Logo = () => {
  const LOGO_WIDTH = isMobile ? 300 : 394
  const LOGO_HEIGHT = isMobile ? 60 : 81
  const LOGO_CORNER_PADDING = 16
  const classes = useStyles()
  const { scrollY } = useViewportScroll()
  const wS = useWindowSize()
  const LOGO_SCROLL_END = wS.height / 3
  const startingX = isMobile
    ? wS.width / 2 - LOGO_WIDTH / 2
    : wS.width / 2 - LOGO_WIDTH / 2 - 7
  const startingY = wS.height / 2 - LOGO_HEIGHT / 2 - 180
  const scrollRange = [0, LOGO_SCROLL_END]
  const bgOpacityScrollRange = [0, wS.height / 2]
  const xRange = [startingX, LOGO_CORNER_PADDING]
  const yRange = [startingY, LOGO_CORNER_PADDING]
  const scaleRange = [1, 0.5]
  const x = useTransform(scrollY, scrollRange, xRange)
  const y = useTransform(scrollY, scrollRange, yRange)
  const scale = useTransform(scrollY, scrollRange, scaleRange)
  const bgOpacity = useTransform(scrollY, bgOpacityScrollRange, [0, 1])

  return (
    <>
      <Link className={classes.linkContainer} to='home' spy smooth>
        <motion.div
          initial={{
            opacity: 0,
            y: -100
          }}
          animate={{
            opacity: 1,
            y: 0
          }}
          style={{
            position: 'fixed',
            top: y,
            left: x,
            scale,
            originX: 0,
            originY: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
            width: LOGO_WIDTH
          }}
        >
          <img src={logoImg} alt='DV Safe Logo' className={classes.img} />
        </motion.div>
      </Link>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: 80,
          background:
            'linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8) 70%, rgba(0,0,0,0))',
          opacity: bgOpacity,
          zIndex: 1
        }}
      />
    </>
  )
}

export default Logo
