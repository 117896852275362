import { useState, useEffect } from 'react'

const useScrollPosition = () => {
  const [output, setOutput] = useState({
    x: window.scrollX,
    y: window.scrollY,
    yProgress:
      window.scrollY / (document.body.scrollHeight - window.innerHeight)
  })

  useEffect(() => {
    const getScroll = () =>
      setOutput({
        x: window.scrollX,
        y: window.scrollY,
        yProgress:
          window.scrollY / (document.body.scrollHeight - window.innerHeight)
      })
    window.addEventListener('scroll', getScroll)
    return () => {
      window.removeEventListener('scroll', getScroll)
    }
  }, [])

  return output
}

export default useScrollPosition
