import React, { lazy, useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { delay } from 'assets/utils/utils'
import axios from 'axios'
import SlideInModal from 'components/Modals/SlideInModal/SlideInModal'
import BMF from 'browser-md5-file'
const UploadFormStepTwo = lazy(() => import('./UploadFormStepTwo'))
const UploadFormStepOne = lazy(() => import('./UploadFormStepOne'))
const UploadCloseUploadAlertModal = lazy(() =>
  import('./UploadCloseUploadAlertModal')
)
const UploadConfirmPhoneDobAlertModal = lazy(() =>
  import('./UploadConfirmPhoneDobAlertModal')
)
const UploadSplashes = lazy(() => import('./UploadSplashes/UploadSplashes'))

const useStyles = makeStyles(theme => ({
  formField: {
    margin: '10px !important',
    width: 280
  },
  disclaimer: {
    maxWidth: 600,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  fieldLabel: {
    color: theme.palette.grey[800],
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    marginBottom: 5
  },
  warning: {
    fontWeight: 600,
    paddingBottom: theme.spacing(2)
  }
}))

const UploadForm = ({ visible, toggleVisibility }) => {
  const classes = useStyles()
  const [visibility, setVisibility] = useState({
    stepOne: true,
    stepTwo: false
  })

  const [commentVisibility, setCommentVisibility] = useState(false)
  const toggleCommentVisibility = () => {
    setCommentVisibility(prev => !prev)
  }
  const [uploadProgress, setUploadProgress] = useState({})

  const [uploadSuccess, setUploadSuccess] = useState(false)

  const [closeAlertVis, setCloseAlertVis] = useState(false)

  const [confirmAlertVis, setConfirmAlertVis] = useState(false)

  const [errorSplashVis, setErrorSplashVis] = useState(false)

  const [xButtonVis, setXButtonVis] = useState(true)

  const toggleConfirmVis = () => setConfirmAlertVis(prev => !prev)

  const [tcModalVis, setTcModalVis] = useState(false)
  const toggleTcModalVis = () => {
    setTcModalVis(prev => {
      if (!prev) {
        if (window.location.hostname === 'dvsafe.org') {
          window.gtag('event', 'Terms & Condition', {
            event_category: 'conversions'
          })
        }
      }
      return !prev
    })
  }
  const onClose = () => {
    setCloseAlertVis(true)
  }

  const handleConfirmClose = handleReset => {
    handleReset()
    setCloseAlertVis(false)
    setVisibility({ stepOne: true, stepTwo: false })
    setUploadSuccess(false)
    setUploadProgress({})
    setXButtonVis(true)
    toggleVisibility()
  }
  const handleCancelClose = () => {
    setCloseAlertVis(false)
  }

  const handleNextButtonClick = async () => {
    setVisibility({ stepOne: false, stepTwo: false })
    await delay(500, () => setVisibility({ stepOne: false, stepTwo: true }))
    if (window.location.hostname === 'dvsafe.org') {
      window.gtag('event', 'Upload Step 2', {
        event_category: 'conversions'
      })
    }
  }
  const handleBackButtonClick = async () => {
    setVisibility({ stepOne: false, stepTwo: false })
    await delay(500, () => setVisibility({ stepOne: true, stepTwo: false }))
    if (window.location.hostname === 'dvsafe.org') {
      window.gtag('event', 'Back To Upload Step 1', {
        event_category: 'conversions'
      })
    }
  }

  const uploadAttachment = async (
    attachments,
    uploadInfo,
    onUploadProgress
  ) => {
    const formData = new FormData()
    Object.entries(uploadInfo.signed.fields).forEach(([k, v]) =>
      formData.append(k, v)
    )
    formData.append('File', attachments[uploadInfo.id].file)
    return axios.post(uploadInfo.signed.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    })
  }

  const getFileMD5 = async file => {
    const bmf = new BMF()
    return new Promise((res, rej) => {
      bmf.md5(file, (err, md5) => {
        if (err) rej(err)
        else res(md5)
      })
    })
  }

  const handleSubmitForm = async ({ phone, dob, attachments }, actions) => {
    setConfirmAlertVis(false)
    setCommentVisibility(false)
    setTcModalVis(false)
    setXButtonVis(false)
    const payload = {
      phone: phone.replace(/[() -]/g, ''),
      dateOfBirth: dob.format('MM/DD/YYYY')
    }
    payload.evidences = await Promise.all(
      attachments.map(async (it, i) => {
        const md5 = await getFileMD5(it.file)
        return {
          id: i,
          contentType: it.file.type,
          fileName: it.file.name,
          md5,
          comment: it.comment
        }
      })
    )
    try {
      // Request signed upload URLs from the API
      const res = await axios.post(
        process.env.REACT_APP_ENV === 'production'
          ? 'https://portal.dvsafe.org'
          : 'https://portal.np.dvsafe.org',
        payload
      )
      // Upload each attachment to the URL generated by the API
      const uploadPromises = res.data.uploads.map(it =>
        uploadAttachment(attachments, it, event => {
          setUploadProgress(prev => ({
            ...prev,
            [it.id]: Math.round((100 * event.loaded) / event.total)
          }))
        })
      )
      await Promise.all(uploadPromises)
      setUploadSuccess(true)
    } catch (e) {
      console.error(e)
      setErrorSplashVis(true)
    }
  }

  const handleSendButtonClick = () => {
    setConfirmAlertVis(true)
    if (window.location.hostname === 'dvsafe.org') {
      window.gtag('event', 'Upload Submit', {
        event_category: 'conversions'
      })
    }
  }

  return (
    <SlideInModal onClose={onClose} visible={visible} xButtonVis={xButtonVis}>
      <Formik
        initialValues={{
          phone: '',
          dob: null,
          attachments: [],
          terms: false
        }}
        validationSchema={Yup.object({
          phone: Yup.string()
            .required('Valid Phone Number Required')
            .matches(
              /^(?:(\+1)[ -])?\(?(\d{3})\)?[ -]?\.?(\d{3})[ -]?\.?(\d{4})$/,
              'Must be valid Phone Number'
            ),
          dob: Yup.date()
            .required('Date of Birth Required')
            .typeError('Date format must be DD/MM/YYYY'),
          terms: Yup.bool().required(
            'You must accept terms and conditions before submitting'
          )
        })}
        validateOnMount
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          submitForm,
          setFieldValue,
          isValid,
          handleReset,
          isSubmitting,
          errors
        }) => (
          <Form>
            <Grid container direction='column' alignItems='center'>
              <Suspense fallback={<div />}>
                <UploadFormStepOne
                  classes={classes}
                  visible={visibility.stepOne}
                  isValid={isValid}
                  handleNextButtonClick={handleNextButtonClick}
                  errors={errors}
                  values={values}
                />
              </Suspense>
              <Suspense fallback={<div />}>
                <UploadFormStepTwo
                  toggleCommentVisibility={toggleCommentVisibility}
                  commentVisibility={commentVisibility}
                  setFieldValue={setFieldValue}
                  values={values}
                  visible={visibility.stepTwo}
                  handleBackButtonClick={handleBackButtonClick}
                  handleSendImagesButtonClick={handleSendButtonClick}
                  uploadProgress={uploadProgress}
                  isSubmitting={isSubmitting}
                />
              </Suspense>
            </Grid>
            <Suspense fallback={<div />}>
              <UploadCloseUploadAlertModal
                handleCancelClose={handleCancelClose}
                handleConfirmClose={handleConfirmClose}
                visible={closeAlertVis}
                handleReset={handleReset}
              />
            </Suspense>
            <Suspense fallback={<span />}>
              <UploadConfirmPhoneDobAlertModal
                visible={confirmAlertVis}
                submitForm={submitForm}
                toggleVisibility={toggleConfirmVis}
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                tcModalVis={tcModalVis}
                toggleTcModalVis={toggleTcModalVis}
              />
            </Suspense>
            <Suspense fallback={<span />}>
              <UploadSplashes
                uploadSuccess={uploadSuccess}
                errorSplashVis={errorSplashVis}
                onFinishClick={() => {
                  handleConfirmClose(handleReset)
                }}
              />
            </Suspense>
          </Form>
        )}
      </Formik>
    </SlideInModal>
  )
}

UploadForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired
}

export default UploadForm
