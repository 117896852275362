/**
 * Invokes callback fn after delay in milliseconds
 * @param callback
 * Callback function to fire after delay
 * @param delay
 * Delay in milliseconds
 * @returns {Promise<boolean>}
 */
export const delay = (delay, callback) =>
  new Promise(resolve =>
    setTimeout(() => {
      if (callback) callback()
      resolve()
    }, delay)
  )

/**
 * Invokes async callback fn after delay in milliseconds.
 * NOTE: Promise will not resolve until after async callback has resolved
 * @param asyncCallback
 * Asynchronous callback to fire after delay
 * @param delay
 * Delay in milliseconds
 * @returns {Promise<unknown>}
 */
export const callbackDelay = (delay, asyncCallback) =>
  new Promise(resolve => {
    setTimeout(async () => {
      await asyncCallback()
      resolve()
    }, delay)
  })

/**
 * Used to join an array of className variables
 * @param classes
 * Array of classNames
 * @returns {string}
 */
export const cls = (classes = []) => classes.join(' ')
