import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import { ThemeProvider } from '@material-ui/core/styles'
import XButton from 'components/Buttons/XButton/XButton'
import modalTheme from 'assets/jss/modalTheme'
import usePreventBodyScroll from 'hooks/usePreventBodyScroll'
import useWindowSize from 'hooks/useWindowSize'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.modal - 3,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    borderRadius: '20px 20px 0px 0px',
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 50
    }
  },
  backdrop: {
    zIndex: theme.zIndex.modal - 4,
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: '100%',
    height: '100vh'
  }
}))

const SlideInModal = ({
  visible,
  onClose,
  children,
  xButtonVis = true,
  ...props
}) => {
  const classes = useStyles()
  usePreventBodyScroll(visible)
  const wS = useWindowSize()

  return (
    <ThemeProvider theme={modalTheme}>
      <Slide direction='up' in={visible} mountOnEnter unmountOnExit>
        <Grid
          className={classes.root}
          container
          direction='column'
          justify='flex-start'
          alignItems='center'
          wrap='nowrap'
          style={{
            height: isMobile ? wS.height * 0.9 : wS.height * 0.6,
            paddingTop: isMobile ? 30 : 50
          }}
          id='slideInModal'
          {...props}
        >
          {xButtonVis && <XButton onClick={onClose} />}
          {children}
        </Grid>
      </Slide>
    </ThemeProvider>
  )
}

SlideInModal.propTypes = {
  /**
   * Boolean to control visibility of modal
   */
  visible: PropTypes.bool.isRequired,
  /**
   * Optional function to fire when modal is closed
   */
  onClose: PropTypes.func,
  /**
   * Optional function to fire before modal closes.
   * beforeClose(resolve, reject)
   * IMPORTANT: you must resolve or reject the promise from the parameters passed into the function.
   */
  beforeClose: PropTypes.func
}

export default SlideInModal
