import React from 'react'
import ReactDOM from 'react-dom'
import 'assets/css/index.css'
import App from 'App'
import mainTheme from 'assets/jss/mainTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import reportWebVitals from 'reportWebVitals'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
