import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
// import { faUpload } from '@fortawesome/free-regular-svg-icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useScrollPosition from '../../../hooks/useScrollPosition'

const useStyles = makeStyles(theme => ({
  upload: {
    zIndex: 2,
    boxShadow: '0 0 5px 0 #111',
    position: 'fixed',
    bottom: 55,
    left: 'calc(50% - 70px)',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    color: theme.palette.common.white,
    width: 140,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 120ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    },
    '&:active': {
      transform: 'scale(1)'
    }
  },
  uploadText: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'nowrap',
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  uploadIcon: {
    marginRight: 10
  }
}))

const UploadButton = ({ onClick }) => {
  const classes = useStyles()
  const isSmallHeight = useMediaQuery('(max-height: 575px)')
  const isSmallWidth = useMediaQuery('(max-width: 760px')
  const { yProgress } = useScrollPosition()
  return (
    !(isSmallHeight && isSmallWidth && yProgress > 0.95) && (
      <div className={classes.upload} onClick={onClick}>
        <FontAwesomeIcon icon={faUpload} size='1x' className={classes.uploadIcon} />
        <span className={classes.uploadText}>Upload</span>
      </div>
    )
  )
}

export default UploadButton
